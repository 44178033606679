// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
body {
  background: #fff;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
