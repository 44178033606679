table.dataTable th.focus,
table.dataTable td.focus
{
    outline: 2px solid $primary;
    outline-offset: -1px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child::before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child::before
{
    font-weight: bold;
    line-height: 15px;

    top: 5px;
    left: 4px;

    width: 14px;
    height: 14px;

    text-indent: 0;

    border-radius: 14px;
}

div.dataTables_wrapper
{
    width: 100%;
    margin: 0 auto;
}

/*
.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td
{
    padding: 1px;
}
*/

.dataTables_scrollBody
{
    position: static !important;
}

.datatable-button-container
{
    margin-bottom: 16px;
}

div.dataTables_wrapper div.dataTables_info
{
    padding-top: 0;

    white-space: nowrap;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child::before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child::before
{
    font-weight: bold;
    line-height: 15px;

    top: 20px;
    left: 4px;

    width: 14px;
    height: 14px;

    text-indent: 0;

    border-radius: 14px;
}

.dataTables_wrapper
{
    width: 100%;
}

@media (max-width: 767px)
{
    .table-responsive .dropdown-menu
    {
        position: static !important;
    }
}

@media (min-width: 768px)
{
    .table-responsive,
    table.responsive
    {
        overflow: visible;
    }
}
