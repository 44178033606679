.pdf-icon {
  color: #212529;
}

.media-item {
  position: relative;
  border: 2px solid $gray-300;
}

.media-item-infobar {
  position: relative;
  border: 2px solid $gray-300;
  border-top: 0;
  background: $gray-300;
  padding: 0.3rem;
  overflow: hidden;
}

.media-item.active {
  border: 2px solid $primary;
}

.p-custom {
  background: #fff !important;
}

.media__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
  opacity: 0;
  padding: 1rem;
  transition: all ease-in-out .25s;
}

/*
.media-item:hover .media__overlay {
    opacity: 1;
    transition: all ease-in-out .25s;
}
*/

.thumbnail.thumbnail-overflow {
  position: relative;
  height: 200px;
  overflow: hidden;
  padding: 10px;
}

.thumbnail.thumbnail-overflow img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.checkered {
  height: 100%;
  background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 5px 5px;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-background-origin: padding-box, padding-box;
  background-origin: padding-box, padding-box;
  -webkit-background-clip: border-box, border-box;
  background-clip: border-box, border-box;
  -webkit-background-size: 10px 10px, 10px 10px;
  background-size: 10px 10px, 10px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
  transform: scaleX(1) scaleY(1) scaleZ(1);
}

.check-item {

}
