.chosen-container-multi .chosen-choices
{
    font-size: 14px;
    line-height: 1.6;

    display: block;

    width: 100%;
    height: 36px;
    padding: 6px 12px;

    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    color: #555;
    border: 1px solid #ccd0d2;
    border-radius: 0;
    background-color: #fff;
    background-image: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.chosen-container-single .chosen-single
{
    line-height: 24px;

    padding: 0 0 0 8px;

    white-space: nowrap;
    text-decoration: none;

    color: #444;
    border: 1px solid #d2d6de;
    border-radius: 0;
    background: #fff;
    background-clip: padding-box;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
       -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.chosen-container-single .chosen-single
{
    line-height: 32px;

    height: 36px;
}
