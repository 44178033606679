// -----------------------------------------------------------------------------
// This file contains all styles related to the frontend profile area.
// -----------------------------------------------------------------------------
.list-group.panel > .list-group-item
{
    border-radius: 0;
}

.list-group-item:first-child
{
    border-radius: 0;
}

.list-group-item:last-child
{
    margin-bottom: 0;

    border-radius: 0;
}
