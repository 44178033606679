.admin-bar
{
    position: fixed;
    z-index: 99999;
    top: 0;

    width: 100%;
    padding: 6px 0;

    border-bottom: 4px solid $primary;
    background: #fff;
    -webkit-box-shadow: 0 13px 23px -9px #949494;
       -moz-box-shadow: 0 13px 23px -9px #949494;
            box-shadow: 0 13px 23px -9px #949494;
}

.admin-bar-left
{
    text-align: left;
}

.admin-bar-right
{
    text-align: right;
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px)
{
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px)
{
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px)
{
    .admin-bar-left,
    .admin-bar-right
    {
        text-align: center;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px)
{
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px)
{
}
