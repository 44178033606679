// -----------------------------------------------------------------------------
// This file contains styles for prototyping. Keep your styles organized!
// -----------------------------------------------------------------------------
.tab-content {
  border-radius: 0 0 0.25rem 0.25rem;
}

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #dee2e6;
}

.list-group-product-sidebar .list-group-item
{
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
}

.list-group-product-sidebar .list-group-item:hover
{
  background: $primary;
  color: #fff;
  cursor: pointer;

  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
}

.carousel-item {
  /*height: 60vh;
  min-height: 300px;*/
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px)
{
  .carousel-item {
    background: url(../img/frontend/slider/autphone_web_default.jpg) no-repeat center center !important;
    /*height: 45vh;*/
  }

  .close-bar, .logo-container {
    padding: 24px 0;
  }

}

.pagination .page-item .page-link {
  background-color: #fff;
}






/* --------------------------------
Slide In Panel - by CodyHouse.co
-------------------------------- */
.cd-main-content {
  text-align: center;
}

.cd-main-content h1 {
  font-size: 2rem;
  color: #64788c;
  padding: 4em 0;
}

.cd-btn {
  position: relative;
  display: inline-block;
  padding: 1em 2em;
  background-color: #89ba2c;
  color: #ffffff;
  border-radius: 50em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.cd-btn:hover {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 1170px) {
  .cd-main-content h1 {
    font-size: 3.2rem;
  }
}

.content-sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.6s;
  transition: visibility 0s 0.6s;
  z-index: 99999;
}

.content-sidebar-panel::after {
  /* overlay layer */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s 0.3s;
  transition: background 0.3s 0.3s;
}

.content-sidebar-panel.content-sidebar-panel--is-visible {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
}

.content-sidebar-panel.content-sidebar-panel--is-visible::after {
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: background 0.3s 0s;
  transition: background 0.3s 0s;
}

.content-sidebar-panel__header {
  position: fixed;
  width: 90%;
  height: 50px;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.96);
  z-index: 2;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: -webkit-transform 0.3s 0s;
  transition: -webkit-transform 0.3s 0s;
  transition: transform 0.3s 0s;
  transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}

.content-sidebar-panel__header h1 {
  color: #89ba2c;
  padding-left: 5%;
}

.content-sidebar-panel--from-right .content-sidebar-panel__header {
  right: 0;
}

.content-sidebar-panel--from-left .content-sidebar-panel__header {
  left: 0;
}

.content-sidebar-panel--is-visible .content-sidebar-panel__header {
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

@media only screen and (min-width: 768px) {
  .content-sidebar-panel__header {
    width: 70%;
  }
}

@media only screen and (min-width: 1170px) {
  .content-sidebar-panel__header {
    width: 50%;
  }
}

.content-sidebar-panel__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  /* image replacement */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.content-sidebar-panel__close::before, .content-sidebar-panel__close::after {
  /* close icon created in CSS */
  content: '';
  position: absolute;
  top: 22px;
  left: 20px;
  height: 3px;
  width: 20px;
  background-color: #424f5c;
  /* this fixes a bug where pseudo elements are slighty off position */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.content-sidebar-panel__close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.content-sidebar-panel__close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.content-sidebar-panel__close:hover {
  background-color: #424f5c;
}

.content-sidebar-panel__close:hover::before, .content-sidebar-panel__close:hover::after {
  background-color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.content-sidebar-panel__close:hover::before {
  -webkit-transform: rotate(220deg);
  -ms-transform: rotate(220deg);
  transform: rotate(220deg);
}

.content-sidebar-panel__close:hover::after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.content-sidebar-panel--is-visible .content-sidebar-panel__close::before {
  -webkit-animation: cd-close-1 0.6s 0.3s;
  animation: cd-close-1 0.6s 0.3s;
}

.content-sidebar-panel--is-visible .content-sidebar-panel__close::after {
  -webkit-animation: cd-close-2 0.6s 0.3s;
  animation: cd-close-2 0.6s 0.3s;
}

@-webkit-keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-webkit-keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.content-sidebar-panel__container {
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
  background: #dbe2e9;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
}

.content-sidebar-panel--from-right .content-sidebar-panel__container {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.content-sidebar-panel--from-left .content-sidebar-panel__container {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.content-sidebar-panel--is-visible .content-sidebar-panel__container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

@media only screen and (min-width: 768px) {
  .content-sidebar-panel__container {
    width: 70%;
  }
}

@media only screen and (min-width: 1170px) {
  .content-sidebar-panel__container {
    width: 50%;
  }
}

.content-sidebar-panel__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 70px 5%;
  overflow: auto;
  /* smooth scrolling on touch devices */
  -webkit-overflow-scrolling: touch;
}

.content-sidebar-panel__content p {
  //font-size: 1.4rem;
  color: #424f5c;
  //line-height: 1.4;
  //margin: 2em 0;
}

.content-sidebar-panel__content p:first-of-type {
  margin-top: 0;
}

@media only screen and (min-width: 768px) {
  .content-sidebar-panel__content p {
    //font-size: 1.6rem;
    //line-height: 1.6;
  }
}