table.tablesorter .tablesorter-header
{
    cursor: pointer;

    background-image: url(../img/bg-desc-asc.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 18px;
}

table.tablesorter thead tr .tablesorter-headerAsc
{
    background-image: url(../img/desc.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 18px;
}

table.tablesorter thead tr .tablesorter-headerDesc
{
    background-image: url(../img/asc.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 18px;
}
