.product-thumb-overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.product-item:hover .product-thumb-overlay {
  opacity: 1;
  transition: all .3s ease-in-out;
}

.product-item:hover {
  -webkit-box-shadow: 0 0 14px #000;
  -moz-box-shadow: 0 0 14px #000;
  box-shadow: 0 0 14px #000;
  transition: all .3s ease-in-out;
}