// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
::-moz-selection
{
    color: #fff;
    background: $primary;
}

::selection
{
    color: #fff;
    background: $primary;
}

html,
body
{
    min-height: 100%;
    margin: 0;
    padding: 0;
}

body
{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
