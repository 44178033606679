
.table-sm td {
  padding: 0.3rem 0.6rem !important;
}

.table-sm th {
  padding: 0.3rem 0.6rem !important;
}

.lock-body
{
  overflow: hidden;

  -webkit-user-select:      none;
  -moz-user-select: -moz-none;
  -ms-user-select:      none;
  user-select:      none;
}

.cssload-container
{
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;

  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px;

  text-align: center;
}

.cssload-speeding-wheel
{
  width: 120px;
  height: 120px;
  margin: 0 auto;

  -webkit-animation: cssload-spin 575ms infinite linear;
  -moz-animation: cssload-spin 575ms infinite linear;
  -ms-animation: cssload-spin 575ms infinite linear;
  -o-animation: cssload-spin 575ms infinite linear;
  animation: cssload-spin 575ms infinite linear;

  border: 5px solid rgb(0, 0, 0);
  border-right-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
}

@keyframes cssload-spin
{
  100%
  {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin
{
  100%
  {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin
{
  100%
  {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin
{
  100%
  {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin
{
  100%
  {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-result,
.form-result h2
{
  color: #fff;
}

.grid-stack-item-content
{
  overflow: hidden !important;
}

.card-full-height
{
  width: 100%;
  height: 100%;
}

.table td,
.table th
{
  vertical-align: middle;
}

.btn-primary
{
  color: $white;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single
{
  border-radius: 0;
}


.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single
{
  height: 38px;

  //border: 1px solid $gray-200;
  border-radius: $border-radius;
}

.is-invalid-select2
{
  border-color: $red !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--single
{
  color: $input-color;
  //border-color: lighten($component-active-bg, 25%);
  outline: 0;
  background-color: $input-bg;
  -webkit-box-shadow: 0 0 0 .2rem rgba(red($primary), green($primary), blue($primary), .2);
  box-shadow: 0 0 0 .2rem rgba(red($primary), green($primary), blue($primary), .2);
}

/*
.select2-container--default
{
  width: 100% !important;
}
*/

.select2-container .select2-selection--single
{
  height: 39px;
  min-height: 39px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered
{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 5px;

  list-style: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow
{
  position: absolute;
  top: 1px;
  right: 1px;

  width: 20px;
  height: 39px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered
{
  line-height: 39px;

  color: #444;
}

#toast-container > .toast
{
  background-image: none !important;
}

#toast-container > .toast:before
{
  font-family: 'Font Awesome 5 Pro';
  font-size: 24px;
  line-height: 18px;

  position: relative;

  float: left;

  margin-right: 0;
  margin-left: -1.4em;
  padding-right: 0;

  color: #fff;
}

#toast-container > .toast-warning:before
{
  content: '\f071';
}

#toast-container > .toast-error:before
{
  content: '\f321';
}

#toast-container > .toast-info:before
{
  content: '\f30f';
}

#toast-container > .toast-success:before
{
  content: '\f14a';
}

/* Alignment styles for images, videos and iframes in editable regions */

/* Center (default) */
[data-editable] iframe,
[data-editable] image,
[data-editable] [data-ce-tag=img],
[data-editable] img,
[data-editable] video
{
  display: block;
  clear: both;

  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

/* Left align */
[data-editable] .align-left
{
  float: left;
  clear: initial;

  margin-right: .5em;
}

/* Right align */
[data-editable].align-right
{
  float: right;
  clear: initial;

  margin-left: .5em;
}

/* Alignment styles for text in editable regions */
[data-editable] .text-center
{
  text-align: center;
}

[data-editable] .text-left
{
  text-align: left;
}

[data-editable] .text-right
{
  text-align: right;
}

.trumbowyg-box,
.trumbowyg-editor
{
  margin-top: 0;
}

.main
{
  padding-bottom: 60px;
}

table.dataTable tbody th.focus,
table.dataTable tbody td.focus
{
  -webkit-box-shadow: inset 0 0 1px 2px $primary;
  box-shadow: inset 0 0 1px 2px $primary;
}

.text-align-right
{
  text-align: right;
}


@media screen and (max-width: 767px)
{
  .center-it
  {
    text-align: center;
  }
}


.sidebar .nav-link.active .nav-icon {
  color: $secondary;
}

.dropdown-menu {
  padding-top: 0 !important;
}

.navbar .dropdown-menu a {
  padding: 10px 20px !important;
}

.ajax-loader {
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  display: none;
  background: rgba(255, 255, 255, .85);
}


.list-group-accent .list-group-item {
  margin-bottom: 1px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
}

.callout {
  border-radius: 0;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

button:focus {
  outline-color: $primary;
}

input:focus {
  outline-color: $primary;
}

.select2-selection--multiple{
  overflow: hidden !important;
  height: auto !important;
}