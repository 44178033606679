// -----------------------------------------------------------------------------
// This file contains all styles related to the fonts of the site/application.
// -----------------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6
{
    margin-top: 0;
    margin-bottom: 32px;

    color: $primary;
}
