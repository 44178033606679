// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.logo-container
{
    margin-top: 64px;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px)
{
    .logo-container
    {
        margin-top: 128px;
    }
}
